import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpParams,
} from '@angular/common/http';
import { Constants } from 'app/shared/config/constants';
import { Observable } from 'rxjs';

@Injectable ( {
  providedIn: 'root',
} )
export class ProductService {

  readonly url = Constants.productsServiceUrl;

  constructor ( private http: HttpClient ) {
  }

  search (
    incidentId: number,
    searchTerm: string,
  ): Observable<any> {
    const params = new HttpParams ()
      .append ( 'searchTerm', searchTerm )
      .append ( 'incidentId', incidentId );
    return this.http.get ( this.url, { params } );
  }
}
